
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
const AutoSaveTermsConditions = () => {
const locale = getData(LANGUAGE);

  return (
    <div style={{display:'flex', justifyContent: 'center'}}>
      
      <iframe src={getLocaleString('autoSaveTermsCondition', locale)} width="100%" height="700px"/>
    </div>
  )
}
export default AutoSaveTermsConditions