import { LANGUAGE } from "../../constants/localStorage";
import { getData } from "../../services/storage";
import { getLocaleString } from "../../utils/locales";
import GoldChart from "./GoldChart";

const GoldChartLanding=()=>{

  const locale = getData(LANGUAGE);
  return <div className="pt-16 space-y-8 lg:pt-28 bg-white md:pt-20 px-9 md:px-20 lg:space-y-0 lg:px-28">
        <div className="w-full lg:flex lg:items-center lg:justify-center">
          <div className="">
            <p className="text-center text-3xl font-semibold text-[#010103]/80 md:text-center md:text-5xl">
              {getLocaleString('gold_over_time', locale)}
            </p>
            <p className="text-[#010103]/54 pt-6 text-xl font-normal text-center lg:max-w-4xl">
              {getLocaleString('gold_over_time_description', locale)}
            </p>
          </div>
        </div>
        <div className="w-full pt-10 lg:flex lg:items-center lg:justify-center">
          {/* <GoldChart/> */}
          <GoldChart></GoldChart>
        </div>
      </div>
}

export default GoldChartLanding