import Papa from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';

interface PickupPointData {
  LOCATION_ID: string;
  DISTRICT_CODE: string;
  DISTRICT_NAME: string;
  BRANCH_NAME: string;
  PICKUP_ADDRESS: string;
  IS_INSIDE_DHAKA: string;
  DISTRICT_NAME_BN: string;
  BRANCH_NAME_BN: string;
  PICKUP_ADDRESS_BN: string;
}

interface FilteredPoint {
  serialNo: number;
  districtEn: string;
  branchEn: string;
  addressEn: string;
  districtBn: string;
  branchBn: string;
  addressBn: string;
  isInsideDhaka: boolean;
}

const debounce = (func: Function, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const PickupPoint = () => {
  const [pickupPoints, setPickupPoints] = useState<PickupPointData[]>([]); 
  const [filteredPoints, setFilteredPoints] = useState<FilteredPoint[]>([]); 
  const [searchTerm, setSearchTerm] = useState<string>('');
  const locale = getData(LANGUAGE);
  const [filterOption, setFilterOption] = useState<'insideDhaka' | 'wholeCountry'>('wholeCountry');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const csvUrl = 'https://docs.google.com/spreadsheets/d/1Zfd9AieND9-vXms3K_0k9B-78WF42lLznkpRGaHGaxg/export?format=csv';

    const fetchPickupPoints = async () => {
      try {
        const response = await fetch(csvUrl);
        const csvData = await response.text();

        Papa.parse<PickupPointData>(csvData, {
          header: true,
          complete: (result: { data: PickupPointData[] }) => {
            setPickupPoints(result.data); 
            setLoading(false);
          },
        });
      } catch (error) {
        console.error('Error fetching pick-up points:', error);
      }
    };

    fetchPickupPoints();
  }, []);

  useEffect(() => {
    const mapFilteredData = () => {
      const mappedData = pickupPoints
        .filter((point) =>
          filterOption === 'wholeCountry' || (filterOption === 'insideDhaka' && point.IS_INSIDE_DHAKA === '1')
        )
        .map((point: PickupPointData, index: number) => ({
          serialNo: index + 1,
          districtEn: point.DISTRICT_NAME,
          branchEn: point.BRANCH_NAME,
          addressEn: point.PICKUP_ADDRESS,
          districtBn: point.DISTRICT_NAME_BN,
          branchBn: point.BRANCH_NAME_BN,
          addressBn: point.PICKUP_ADDRESS_BN,
          isInsideDhaka: point.IS_INSIDE_DHAKA === '1',
        }));
      
      if (searchTerm.trim() !== '') {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filteredResults = mappedData.filter(
          (point) =>
            point.districtEn.toLowerCase().includes(lowercasedTerm) ||
            point.branchEn.toLowerCase().includes(lowercasedTerm) ||
            point.addressEn.toLowerCase().includes(lowercasedTerm) ||
            point.districtBn.toLowerCase().includes(lowercasedTerm) ||
            point.branchBn.toLowerCase().includes(lowercasedTerm) ||
            point.addressBn.toLowerCase().includes(lowercasedTerm)
        );
        setFilteredPoints(filteredResults);
      } else {
        setFilteredPoints(mappedData);
      }
    };

    mapFilteredData();
  }, [pickupPoints, locale, filterOption, searchTerm]);

  const handleSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleSearch(value);
  };

  return (
    <div className="flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mt-20 mb-4">
        {locale === 'en' ? 'Gold Kinen - Sundarban Delivery Points' : 'গোল্ড কিনেন - সুন্দরবন বিতরণ কেন্দ্রের তালিকা'}
      </h2>

      <div className="mb-4 flex flex-wrap justify-center space-x-2 gap-3">
        <button
          className={`px-4 py-2 rounded-md transition-colors ${filterOption === 'insideDhaka' ? 'bg-[#e9be5a] text-white' : 'bg-[#081939] text-gray-200'}`}
          onClick={() => setFilterOption('insideDhaka')}
        >
          {locale === 'en' ? 'Inside Dhaka' : 'ঢাকার মধ্যে'}
        </button>
        <button
          className={`px-4 py-2 rounded-md transition-colors ${filterOption === 'wholeCountry' ? 'bg-[#e9be5a] text-white' : 'bg-[#081939] text-gray-200'}`}
          onClick={() => setFilterOption('wholeCountry')}
        >
          {locale === 'en' ? 'Whole Country' : 'সম্পূর্ণ দেশ'}
        </button>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder={locale === 'en' ? 'Search pick up locations near you' : 'পিক-আপ লোকেশন খুজুন'}
          onChange={handleSearchChange}
          className="border px-4 py-2 w-64 md:w-96 rounded focus:outline-none focus:ring-1 focus:ring-[#e9be5a] focus:border-[#e9be5a]"
        />
      </div>

      <div className="overflow-x-auto w-full max-w-4xl">
        <table className="table-auto border-collapse w-full text-left border border-gray-300 mx-auto">
          <thead>
            <tr>
              <th className="border px-4 py-2">{locale === 'en' ? 'District' : 'জেলা'}</th>
              <th className="border px-4 py-2">{locale === 'en' ? 'Branch Name' : 'শাখার নাম'}</th>
              <th className="border px-4 py-2">{locale === 'en' ? 'Pickup Address' : 'পিক-আপ ঠিকানা'}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan={3}>
                  {locale === 'en' ? 'Loading Pick Locations....' : 'পিক-আপ লোকেশন লোড হচ্ছে'}
                </td>
              </tr>
            ) : filteredPoints.length > 0 ? (
              filteredPoints.map((point) => (
                <tr key={point.serialNo}>
                  <td className="border px-4 py-2">{locale === 'en' ? point.districtEn : point.districtBn}</td>
                  <td className="border px-4 py-2">{locale === 'en' ? point.branchEn : point.branchBn}</td>
                  <td className="border px-4 py-2">{locale === 'en' ? point.addressEn : point.addressBn}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan={3}>
                  {locale === 'en' ? 'No location found' : 'কোনো ঠিকানা পাওয়া যায় নি'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PickupPoint;
