import React from 'react';
import ImageContainer from '../../components/ui/ImageContainer';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';

interface ServiceItemProps {
  imageSrc: string;
  titleKey: string;
  descriptionKey: string;
  imageRight: boolean;
}

interface BenefitItemProps {
  iconSrc: string;
  titleKey: string;
  descriptionKey: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ imageSrc, titleKey, descriptionKey, imageRight }) => {
  const locale = getData(LANGUAGE);
  return (
    <div className={`w-full px-0 lg:flex lg:py-10 lg:px-36 ${imageRight ? 'flex-row-reverse' : ''}`}>
      <div className="flex items-center justify-center w-full lg:w-1/2">
        <ImageContainer src={imageSrc} alt="service" className="" />
      </div>
      <div className={`flex items-start w-full pt-6 lg:w-1/2 lg:pt-20 ${imageRight ? 'lg:pr-16' : 'lg:pl-16'}`}>
        <div>
          <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString(titleKey, locale)}</p>
          <p className="text-[#010103]/54 text-base font-normal text-justify">
            {getLocaleString(descriptionKey, locale)}
          </p>
        </div>
      </div>
    </div>
  );
};

const BenefitItem: React.FC<BenefitItemProps> = ({ iconSrc, titleKey, descriptionKey }) => {
  const locale = getData(LANGUAGE);
  return (
    <div>
      <div className="flex space-x-2 md:space-x-4">
        <ImageContainer src={iconSrc} alt="check" className="" />
        <p className="pb-4 text-2xl font-semibold text-[#010103]/80">
          {getLocaleString(titleKey, locale)}
        </p>
      </div>
      <p className="text-[#010103]/54 px-12 text-base font-normal">
        {getLocaleString(descriptionKey, locale)}
      </p>
    </div>
  );
};

const Services: React.FC = () => {
  const locale = getData(LANGUAGE);
  const services: ServiceItemProps[] = [
    { imageSrc: '/image/services/BuyGold.jpg', titleKey: 'buy_gold', descriptionKey: 'buy_gold_description', imageRight: false },
    { imageSrc: '/image/services/SaveGold.jpg', titleKey: 'save_gold', descriptionKey: 'save_gold_description', imageRight: true },
    { imageSrc: '/image/services/CollectGold.jpg', titleKey: 'collect_gold', descriptionKey: 'collect_gold_description', imageRight: false },
    { imageSrc: '/image/services/GiftGold.jpg', titleKey: 'gift_gold', descriptionKey: 'gift_gold_description', imageRight: true },
    { imageSrc: '/image/services/SellGold.jpg', titleKey: 'sell_gold', descriptionKey: 'sell_gold_description', imageRight: false },
  ];

  const benefits: BenefitItemProps[] = [
    { iconSrc: "/image/services/check.svg", titleKey: 'buy_save_comfort', descriptionKey: 'buy_save_comfort_description' },
    { iconSrc: "/image/services/check.svg", titleKey: 'store_with_assurance', descriptionKey: 'store_with_assurance_description' },
    { iconSrc: "/image/services/check.svg", titleKey: 'gain_safety', descriptionKey: 'gain_safety_description' },
    { iconSrc: "/image/services/check.svg", titleKey: 'sell_fairness', descriptionKey: 'sell_fairness_description' },
    { iconSrc: "/image/services/check.svg", titleKey: 'redeem_essentiality', descriptionKey: 'redeem_essentiality_description' },
  ];

  return (
    <div id="service" className='mt-5'>
      <div className="bg-[#D9D9D9]/10">
        <div className="flex items-center justify-center py-9 md:py-12 lg:py-16 ">
          <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">
            {getLocaleString('services_benefits', locale)}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">{getLocaleString('services', locale)}</p>
      </div>
      <div className="mt-10 bg-white px-9 md:mt-20 md:px-20 lg:mt-8 lg:px-28">
        {services.map((service, index) => (
          <ServiceItem key={index} {...service} />
        ))}
        <div className="pt-20 pb-24">
          <div className="flex items-center justify-center">
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">{getLocaleString('benefit', locale)}</p>
          </div>
          <div className="flex items-center justify-center w-full pt-9 md:px-0 lg:px-40">
            <div className="space-y-10">
              {benefits.map((benefit, index) => (
                <BenefitItem key={index} {...benefit} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;