import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';

 const ScheduledCharges = () => {
  const locale = getData(LANGUAGE);

  return (
    <div className="my-14 mx-5 lg:mx-40 text-justify space-y-4 pt-10 lg:pt-10 md:pt-10">
      <h4 className="flex justify-center text-xl uppercase font-semibold">{getLocaleString('schedule_of_charges', locale)}</h4>
      
        
<div className="relative overflow-x-auto lg:mx-20  rounded-lg ">
    <table className="w-full text-sm text-left  text-gray-400 ">
        <thead className="text-xs  uppercase  bg-[#e9be5a] text-[#081939]">
            <tr>
                <th scope="col" className="px-6 w-2/3 py-3 text-[#081939]">
                    {getLocaleString('service_charges', locale)}
                </th>
                <th scope="col" className={`${locale==='en'?'pl-[1rem] lg:pl-0 pr-[1.4rem] lg:pr-[38px]':'pr-[1.8rem] lg:pl-[.05rem] lg:pr-[3.8rem] xl:pr-[3.5rem]'} text-left py-3 text-[#081939]`}>
                    {getLocaleString('rate', locale)} 
                </th>
            </tr>
        </thead>
        <tbody className='w-full'>
            {/* <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('buy', locale)}
                </th>
                <td className={`${[locale==='en'?'pl-[1rem]':'mx-14']} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('buy_service_charge', locale)}
                </td>
            </tr> */}
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('collect', locale)}
                </th>
                <td className={`${[locale==='en'?'pl-[1rem] lg:pl-0':'mx-14']} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('collect_service_charge', locale)}
                </td>
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('refund_app', locale)}
                </th>
                <td className={`${[locale==='en'?'pl-[1rem] lg:pl-0':'mx-14']} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('refund_service_charge_app', locale)}
                </td>              
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('refund_show_room', locale)}
                </th>
                <td className={`${[locale==='en'?'pl-[1rem] lg:pl-0':'mx-14']} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('refund_service_charge_showroom', locale)}
                </td>              
            </tr>
            {/* <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('gift', locale)}
                </th>
                <td className={`${[locale==='en'?'pl-[1rem]':'mx-14']} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('gift_service_charge', locale)}
                </td>              
            </tr>          */}
        </tbody>
    </table>
</div>


<div className="relative overflow-x-auto lg:mx-20  rounded-lg">
    <table className="w-full text-sm text-left  text-gray-400 ">
        <thead className="text-xs  uppercase  bg-[#e9be5a] text-[#081939]">
            <tr>
                <th scope="col" className={`${locale==='en'?'px-6 lg:pr-[2.5rem] lg:pl-6':'pr-[6rem] pl-6 lg:pr-[3.5rem] lg:pl-6'} py-3 text-[#081939] whitespace-nowrap`}>
                    <p>{getLocaleString('delivery_charges', locale)}</p>
                    {/* <p className="my-1">(Insured)</p> */}
                </th>
                <th scope="col" className={`${locale==='en'?'pl-[1rem] lg:pl-0 pr-[3rem] lg:pr-[1rem]':'pr-[3.2rem] lg:pr-[6.3rem]'} py-3 text-[#081939]`}>
                    {getLocaleString('rate', locale)} 
                </th>          
            </tr>
        </thead>
        <tbody>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('two_gram_coin_delivery_charge', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('two_gram_coin_delivery_charge_rate', locale)}
                </td>          
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('four_gram_coin_delivery_charge', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('four_gram_coin_delivery_charge_rate', locale)}
                </td>          
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('one_gram_bar_delivery_charge', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('one_gram_bar_delivery_charge_rate', locale)}
                </td>             
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('five_gram_bar_delivery_charge', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 clg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('five_gram_bar_delivery_charge_rate', locale)}
                </td>
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('ten_gram_bar_delivery_charge', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('ten_gram_bar_delivery_charge_rate', locale)}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div className="relative overflow-x-auto lg:mx-20  rounded-lg">
    <table className="w-full text-sm text-left  text-gray-400 ">
        <thead className="text-xs  uppercase  bg-[#e9be5a] text-[#081939]">
            <tr>
                <th scope="col" className={`${locale==='en'?'pr-[2rem] pl-6 lg:pr-[2.95rem] lg:pl-[1.5rem]':'pr-[5.5rem] pl-6 lg:pr-[2.7rem] lg:pl-6'} py-3 text-[#081939] whitespace-nowrap`}>
                    <p>{getLocaleString('pickup_charges', locale)}</p>
                    {/* <p className="my-1">(Insured)</p> */}
                </th>
                <th scope="col" className={`${locale==='en'?'pl-[1rem] lg:pl-0 pr-[3rem] lg:pr-[1rem]':'pr-[3.2rem] lg:pr-[6.3rem]'} py-3 text-[#081939]`}>
                    {getLocaleString('rate', locale)} 
                </th>          
            </tr>
        </thead>
        <tbody>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('inside_Dhaka', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('inside_Dhaka_charge', locale)}
                </td>          
            </tr>
            <tr className="border-b bg-[#081939] border-gray-700">
                <th scope="row" className="px-6 w-2/3 py-4 font-medium  whitespace-nowrap text-[#e9be5a]">
                    {getLocaleString('outside_Dhaka', locale)}
                </th>
                <td className={`${locale==='en'?'pl-[1rem] lg:pl-0 lg:pr-[3.7rem]':'lg:[pl-[1rem] pr-[3rem]'} py-4 whitespace-nowrap text-[#e9be5a]`}>
                    {getLocaleString('outside_Dhaka_charge', locale)}
                </td>          
            </tr>
            
        </tbody>
    </table>
</div>
</div>
  )
}
export default ScheduledCharges