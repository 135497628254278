import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ImageContainer from '../../components/ui/ImageContainer';
// import CloseIcon from '@material-ui/icons/Close';
import { AiFillCloseSquare } from 'react-icons/ai';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

function Footer() {
  const locale = getData(LANGUAGE);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="bg-footerBg py-8 px-9 text-white md:px-20 lg:px-28">
        <div className="justify-between border-b border-[#B5BAC4]/20 pb-8 md:flex">
          <div>
            <ImageContainer
              src={`${getLocaleString('Logo', locale)}`}
              alt="gklogo"
              className="h-18 w-44 object-scale-down md:max-w-md xs:object-fill"
            />
          </div>
          <div className='lg:px-10'>
            {' '}
            <div className="mt-4 flex space-x-6">
              <a href="https://www.facebook.com/goldkinen" target="_blank" className="block">
                <FaFacebook size={30} />
              </a>
              <a href="https://www.instagram.com/goldkinen/" target="_blank" className="block">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.youtube.com/@goldkinen" target="_blank" className="block">
                <FaYoutube size={30} />
              </a>
              {/* <Link to="#" className="block">
                <FaTwitter size={20} />
              </Link>
              <Link to="#">
                <FaLinkedinIn size={20} />
              </Link> */}
            </div>
          </div>
        </div>

        <div className="hidden gap-4 py-4 pt-8 lg:visible lg:grid lg:grid-cols-4">
          <div className="space-y-4">
            {/* <Link to="/">
              <p className="text-sm font-normal text-[#FFFFFF]">Compliance</p>
            </Link> */}
            <Link
                  to={`${locale}/faq`}
                  className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString('faq', locale)}</p>
                  
                </Link>

                
            <Link
                  to={`${locale}/how-it-works`}
                  className="block"
                >
                   <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString('how_it_works', locale)}</p>
                </Link>


            <Link
                  to={`${locale}/contact-us`}
                  className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString('contact_us', locale)}</p>
                </Link>
          </div>
          <div className="space-y-4">
            <Link
                  to={`${locale}/about-us`}
                  className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString('about_us', locale)}</p>
                </Link>
            <Link to={`/${locale}/privacy_policy`} className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`privacy_policy`, locale)}</p>
            </Link>
            <Link to={`/${locale}/terms-of-use`} className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`terms_conditions`, locale)}</p>
            </Link>
            <Link to={`/${locale}/pickup-points`} className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`pickup`, locale)}</p>
            </Link>
          </div>
          <div className="space-y-4">
            <Link to={`/${locale}/refund_policy`} className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`refund_policy`, locale)}</p>
            </Link>
            <Link to={`/${locale}/delivery_protocol`} className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`delivery_policy_protocol`, locale)}</p>
            </Link>
            <Link
                  to={`${locale}/scheduled-charges`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`schedule_of_charges`, locale)}</p>
                </Link>

            <a href='javascript:void()' className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">
                <strong>Trade License No:</strong> TRAD/DNCC/037208/2022 <br />
                <strong>BIN:</strong> <a href='https://nbr.gov.bd/' target="_blank">004827615-0101</a> <br/>
                <strong>DBID No:</strong> <a href='https://dbid.gov.bd/verified-dbid-company' target="_blank">751116006</a>
              </p>
            </a>
          </div>
          <div className="space-y-2">
            <p className="text-start text-sm font-normal text-[#FFFFFF]/60 ">
          {getLocaleString(`address`, locale)}
        </p>

        <p className="text-start text-sm font-normal text-[#FFFFFF] ">
          {getLocaleString(`email`, locale)} : <span><a href='mailto:info@goldkinen.com'>info@goldkinen.com</a></span>
        </p>
            <p className="text-xl font-bold text-white justify-center pb-2">{getLocaleString(`download_app`, locale)}</p>
            <div className="pb-4 text-sm flex space-x-5 font-normal leading-6 text-[#FFFFFF]/50">
              {/* Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
              today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
              yourself. */}
              <a href='https://play.google.com/store/apps/details?id=com.goldkinen.android' target="_blank"><ImageContainer src="/image/footer/Play Store Icon.png" alt="Play Store" className="h-16 lg:h-11" /></a>
              <a href='https://apps.apple.com/bn/app/gold-kinen/id6443875166' target="_blank"><ImageContainer src="/image/footer/App Store Icon.png" alt="App Store" className="h-16 lg:h-11" /></a>
            </div>

            
          </div>
        </div>
        <div className="lg:hidden">
          <div>
            <div className="grid grid-cols-2 gap-4 py-4 pt-8 md:grid-cols-3 ">
              <div className="space-y-4">
                {/* <Link to="/">
                  <p className="text-sm font-normal text-[#FFFFFF]">Compliance</p>
                </Link> */}
                
                <Link
                  to={`${locale}/faq`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`faq`, locale)}</p>
                </Link>
                

                <Link
                  to={`${locale}/how-it-works`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`how_it_works`, locale)}</p>
                </Link>
                
                <Link
                  to={`${locale}/contact-us`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`contact_us`, locale)}</p>
                </Link>
                
                <Link
                  to={`${locale}/about-us`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`about_us`, locale)}</p>
                </Link>
                 <Link to={`/${locale}/pickup-points`} >
              <p className="text-sm font-normal text-[#FFFFFF] my-4">{getLocaleString(`pickup`, locale)}</p>
            </Link>
              </div>
              <div className="space-y-4">
                
                <Link
                  to={`${locale}/privacy_policy`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`privacy_policy`, locale)}</p>
                </Link>

                <Link
                  to={`${locale}/terms-of-use`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`terms_conditions`, locale)}</p>
                </Link>
                
                <Link
                  to={`${locale}/refund_policy`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`refund_policy`, locale)}</p>
                </Link>
                <Link
                  to={`${locale}/delivery_protocol`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`delivery_policy_protocol`, locale)}</p>
                </Link>
                <Link
                  to={`${locale}/scheduled-charges`}
                 className="block"
                >
                  <p className="text-sm font-normal text-[#FFFFFF]">{getLocaleString(`schedule_of_charges`, locale)}</p>
                </Link>
                <a href='javascript:void()' className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">
                    <strong>Trade License No:</strong> TRAD/DNCC/037208/2022 <br />
                    <strong>BIN:</strong> <a href='https://nbr.gov.bd/' target="_blank">004827615-0101</a> <br/> <br/>
                    <strong>DBID No:</strong> <a href='https://dbid.gov.bd/verified-dbid-company' target="_blank">751116006</a> <br />
                  </p>
                </a>
              </div>
              {/* <div className="space-y-4">
                <Link to="/terms-of-use">
                  <p className="text-sm font-normal text-[#FFFFFF]">Terms of use</p>
                </Link>
                <Link to="/refund_policy">
                  <p className="text-sm font-normal text-[#FFFFFF]">Refund Policy</p>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="w-full py-16 md:w-96">
            <div className="space-y-2">
              <p className="text-start text-sm font-normal text-[#FFFFFF]/60 ">
          {getLocaleString(`address`, locale)}
        </p>
        <p className="text-start text-sm font-normal text-[#FFFFFF] ">
          {getLocaleString(`email`, locale)} : <span><a href='mailto:info@goldkinen.com'>info@goldkinen.com</a></span>
        </p>
              <p className="text-xl font-bold text-white">{getLocaleString(`download_app`, locale)}</p>
              <div className="pb-4 text-sm flex space-x-5 font-normal leading-6 pt-2 text-[#FFFFFF]/50">
              {/* Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
              today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
              yourself. */}
              <a href='https://play.google.com/store/apps/details?id=com.goldkinen.android' target="_blank"><ImageContainer src="/image/footer/Play Store Icon.png" alt="Play Store" className="h-11 lg:h-11" /></a>
              <a href='https://apps.apple.com/bn/app/gold-kinen/id6443875166' target="_blank"><ImageContainer src="/image/footer/App Store Icon.png" alt="App Store" className="h-11 lg:h-11" /></a>
            </div>

              {/* <p className="pb-4 text-sm font-normal text-[#FFFFFF]/50">
                Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
                today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
                yourself.
              </p>

              <button className="rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br py-3 px-14 text-base font-semibold text-primary hover:text-white" onClick={handleOpen}>
                Download App
              </button> */}
            </div>
          </div>
        </div>
        {/* <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-2">
          Trade License No: TRAD/DNCC/037208/2022
        </p>
        <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-2">
          Registered Address: House-84, Level-6, Road-11, Block-D, Banani, Dhaka-1213
        </p> */}
        <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-0">
          © {new Date().getFullYear()} Gold Kinen Technologies Ltd. All Rights Reserved
        </p>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
         <div className="bg-white border rounded-2xl focus:outline-none">
          <div className="w-auto">
            <div className="flex items-center justify-between px-6 py-4">
              <p className="pr-4 text-lg font-semibold">{getLocaleString(`download_app`, locale)}</p>
              <div
                className="cursor-pointer bg-white border border-gray-100 inline-block p-2.5 rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out"
                onClick={handleClose}
              >
                <AiFillCloseSquare
                  name="cross"
                  className="h-4 text-black fill-current stroke-current stroke-1"
                />
              </div>
            </div>
            <div className="p-6 overflow-auto flex justify-between text-center" style={{ maxHeight: '70vh' }}>
              <ImageContainer src="/image/footer/Play Store Icon.png" alt="Play Store" className="h-16 lg:h-44" />
            </div>
          </div>
        </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Footer;
