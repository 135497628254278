import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTop from './components/ScrollTotop';
import { LANGUAGE } from './constants/localStorage';
import useLanguageSwitch from './hooks/useLanguageSwitch';
import Routes from './routes/Routes';
import { getData, setData } from './services/storage';
import './styles/global.css';
import './styles/tailwind.css';

function App() {
  const { handleLanguageToggle } = useLanguageSwitch();
  const location = useLocation();

  useEffect(() => {
    const urlLocale = location.pathname.split('/')[1];
    const supportedLocales = ['en', 'bn'];
    let locale;

    if (supportedLocales.includes(urlLocale)) {
      locale = urlLocale;
      setData(LANGUAGE, locale);  
    } else {
      locale = getData(LANGUAGE) || 'bn';
    }

    handleLanguageToggle(locale);
  }, [location.pathname]);
  
  return (
    <div>
      
      

      <ScrollToTop/>
        <Routes />
    </div>
  );
}

export default App;
